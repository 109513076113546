import './App.css';


import Footer from './components/Footer';

import { BrowserRouter as Router } from 'react-router-dom';




function App() {
  return (
    <Router>
   


        <Footer></Footer>

    </Router>
  );
}

export default App;
